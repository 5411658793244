/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.container {
  min-height: 100vh;
  overflow: auto;
  background: #f0f2f5;
  background-image: url('../../assets/back_dot.svg');
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
}
