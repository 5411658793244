/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.globalFooter {
  margin: 48px 0 24px 0;
  padding: 0 16px;
  text-align: center;
}
.globalFooter .links {
  margin-bottom: 8px;
}
.globalFooter .links a {
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
.globalFooter .links a:not(:last-child) {
  margin-right: 40px;
}
.globalFooter .links a:hover {
  color: rgba(0, 0, 0, 0.85);
}
.globalFooter .copyright {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
