/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.menu :global(.anticon) {
  margin-right: 8px;
}
.menu :global(.ant-dropdown-menu-item) {
  min-width: 160px;
}
.right {
  float: right;
  height: 100%;
  overflow: hidden;
}
.right .action {
  display: inline-block;
  height: 100%;
  padding: 0 12px;
  cursor: pointer;
  transition: all 0.3s;
}
.right .action > i {
  color: rgba(0, 0, 0, 0.85);
  vertical-align: middle;
}
.right .action:hover {
  background: rgba(0, 0, 0, 0.025);
}
.right .action:global(.opened) {
  background: rgba(0, 0, 0, 0.025);
}
.right .search {
  padding: 0 12px;
}
.right .search:hover {
  background: transparent;
}
.right .account .avatar {
  margin: calc((64px - 24px) / 2) 0;
  margin-right: 8px;
  color: #1890ff;
  vertical-align: top;
  background: rgba(255, 255, 255, 0.85);
}
