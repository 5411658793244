/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.root .header {
  padding: 0;
  background-color: #fff;
}
.root .content {
  margin: 16px 16px 0;
  overflow: 'initial';
}
.root .content > :global(.ant-card) {
  margin-bottom: 16px;
}
.root .content > :global(.ant-card:last-child) {
  margin-bottom: 0;
}
.root .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  display: inline-block;
}
.root .trigger:hover {
  background-color: #f5f5f5;
  color: #1890ff;
}
.root .logo {
  position: relative;
  height: 64px;
  padding-left: 24px;
  overflow: hidden;
  line-height: 64px;
  transition: all 0.3s;
}
.root .logo img {
  display: inline-block;
  height: 32px;
  vertical-align: middle;
}
.root .logo h1 {
  display: inline-block;
  margin: 0 0 0 12px;
  color: white;
  font-weight: 600;
  font-size: 20px;
  vertical-align: middle;
  letter-spacing: 0.5em;
}
